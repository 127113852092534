<template>
    <div class="login">
        <!--<div class="header">-->
            <!--<div class="content">-->
                <!--<div class="left">-->
                      <!--<img class="logo" src="../assets/img/logo.png" alt="">-->
                   <!--&lt;!&ndash; <div class="text">中波培训系统</div> &ndash;&gt;-->
                <!--</div>-->
                <!--<div class="right">-->
                    <!--<a>进入后台</a>-->
                <!--</div>-->
            <!--</div>-->
        <!--</div>-->
        <!-- <div class="banner">
                    <el-carousel height="733px"  arrow="never" trigger="click" >
                        <el-carousel-item v-for="item in bannerlist" :key="item.id">
                                <img class="small" v-lazy="item.imgUrl" alt="">
                        </el-carousel-item>
                    </el-carousel>
            </div> -->
        <div class="cont">

            <div class="login">
                <div class="text">
                    <img style="width: 200px" src="../assets/img/logo.png"/>
                </div>
                <el-form :model="ruleForm2" :rules="rules2"
                    status-icon
                    ref="ruleForm2"
                    label-position="left"
                    label-width="0px"
                    class="demo-ruleForm login-page">
                        <el-form-item prop="username">
                            <span class="iconfont">&#xe720;</span>
                            <span class="boder"></span>
                            <el-input type="text"
                                v-model="ruleForm2.username"
                                auto-complete="off"
                                placeholder="工号"
                                clearable
                            ></el-input>
                        </el-form-item>
                        <el-form-item prop="password">
                            <span class="iconfont">&#xe725;</span>
                            <span class="boder"></span>
                            <el-input type="password"
                                v-model="ruleForm2.password"
                                auto-complete="off"
                                placeholder="密码"
                                clearable
                                @keyup.enter.native="handleLogin"
                            ></el-input>
                        </el-form-item>
                        <el-form-item >
                            <el-button type="default" size="small" style="width:80%;" @click="handleSubmit" :loading="logining" :disabled=show>{{!show?'立即登录':'正在登录'}}</el-button>
                        </el-form-item>
                    </el-form>
                <div class="ftext">
                    未开通账号请联系管理员开通
                </div>
            </div>
        </div>
        <div class="footer_login">
            <div class="text">
           Copyright  {{ year }} <a href="https://www.tdin360.com">贵州通达智慧信息技术有限公司</a> 版权所有
            </div>
        </div>
    </div>
</template>

<script>
import { PostUserList } from "../api/user";
    export default {
        data(){
            return {
                year:'',
                show:false,
                logining: false,
                token:'',
                uname:'请输入工号',
                pass:'请输入密码',
                ruleForm2: {
                    username: '',
                    password: '',
                },
                bannerlist:[],
                rules2: {
                    username: [{required: true, message: '请输入工号', trigger: 'blur'}],
                    password: [{required: true, message: '请输入密码', trigger: 'blur'}]
                },
                checked: false
            }
        },
        created(){

             this.year = new Date().getFullYear()

        },
        methods: {
            handleSubmit(){

                this.$refs['ruleForm2'].validate((validate)=>{

                    if(validate){
                        this.show=true
                        let formData = new FormData();
                        formData.append("username",this.ruleForm2.username)
                        formData.append("password",this.ruleForm2.password)
                        PostUserList(formData).then(res=>{
                            this.$store.commit('user/initUinfoMut',res.data.userInfo)
                            sessionStorage.setItem("user",JSON.stringify(res.data.userInfo))
                            sessionStorage.setItem("token",res.data.token)
                            this.token=res.data.token
                            this.$router.replace('/home');
                        }).catch(e=>{
                            this.show=false
                        })
                    }

                })


            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            // focus(ev){
            //     ev.path[2].childNodes[0].style.color='#007AFF'
            // },
            // blur(ev){
            //     ev.path[2].childNodes[0].style.color='#e4e4e4'
            // },
            handleLogin(){
                let e = window.event||arguments.callee.caller.arguments[0];
                if(e.keyCode==13){
                    this.handleSubmit()
                }
            }
        },
        destroyed () {
            sessionStorage.setItem('state',JSON.stringify([{"answer":"","id":"","ans":[]}]))
            sessionStorage.setItem('textTime',12)
        },
        beforeDestroy () {
             sessionStorage.setItem('state',JSON.stringify([{"answer":"","id":"","ans":[]}]))
            sessionStorage.setItem('textTime',12)

        },
        beforeDestroy () {
            sessionStorage.setItem("token",this.token)
        },
    }

</script>

<style  scoped>
@import url(../assets/css/Login.css);





</style>
