import request from '../utils/request'
export const PostUserList=(data)=>{        //登录
    return request.post(`/api/user/login`,data)
}
export const PostUserPass=(pass)=>{              //修改密码
    return request.put(`/api/user/restPwd`,pass)
}

//获取我的课程
export const GetMyCourseList=(page,pageSize)=>{

    return request.get(`/api/videoCollect/getVideoCollect?page=${page}&limit=${pageSize}`)
}

//获取收藏文章
export const GetMyCollectArticleList=(page,pageSize)=>{

    return request.get(`/api/articleCollect/getArticle?page=${page}&limit=${pageSize}`)
}